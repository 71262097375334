<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">品號換算單位介面</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { callback(); }"
        >
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'

export default defineComponent({
  components: {
    Grid,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      mode: 'inline',
      id: 'unitConversion',
      title: '品號換算單位',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'ProductNo', title: '品號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: 'Name',
          title: '品名',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        { field: 'Specifications', title: '規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: 'StockUint', title: '庫存單位', showHeaderOverflow: true, showOverflow: true, sortable: true,
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'text' },
          }
        },
        {
          field: 'SmallestUnit', title: '小單位', showHeaderOverflow: true, showOverflow: true, sortable: true,
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'text' },
          }
        },
        { field: 'PackageUint', title: '包裝單位', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Molecular', title: '換算率分子', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Rate', title: '換算單位', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Denominator', title: '換算率分母', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'operate', title: '編輯', slots: { default: 'operate' } },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('unitConversion/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('unitConversion/query') : undefined,
        save: model
          ? (params) => model.dispatch('unitConversion/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'ProductNo',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Name',
          title: '品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Specifications',
          title: '規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'StockUint',
          title: '庫存單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'SmallestUnit',
          title: '小單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PackageUint',
          title: '包裝單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Molecular',
          title: '轉換率分子',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Rate',
          title: '換算單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Denominator',
          title: '轉換率分母',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
